import Head from 'next/head';
import type { FC } from 'react';
import { globalVariable } from '@/lib/globals';

const defaultKeywords = [
  'talent garden',
  'platform',
  'learn',
  'education',
  'tag',
  'talent',
  'garden',
  'master',
];

type NormalizedMeta = {
  title?: string;
  description?: string;
  keywords?: string[] | [];
  noFollow?: boolean;
};

export const Meta: FC<NormalizedMeta> = ({
  title = 'Learn | Talent Garden',
  keywords = defaultKeywords,
  description = 'Manage your course agenda and your learning materials, find out when you have the next lessons and get in touch with other students.',
  noFollow = true,
}) => {
  const isProduction = process.env.NODE_ENV === 'production';

  let customTitle = title;
  let siteName = 'Talent Garden';
  let url = 'https://learn.talentgarden.com/';
  let icon = 'https://cdn.talentgarden.com/images/favicon/favicon.ico';

  let favicon =
    'https://cdn.talentgarden.com/images/favicon/apple-touch-icon.png';

  if (globalVariable.theme === 'hi') {
    customTitle = title?.replace('Talent Garden', 'Hyper Island');
    siteName = 'Hyper Island';
    url = 'https://learn.hyperisland.com/';
  } else if (globalVariable.theme === 'tsh') {
    customTitle = title?.replace('Talent Garden', 'The Social Hub');
    siteName = 'The Social Hub';
    url = 'https://tsh.talentgarden.com/';
  } else if (globalVariable.theme === 'hypertsh') {
    customTitle = title?.replace('Talent Garden', 'Hyper Island');
    siteName = 'Hyper Island';
    url = 'https://tsh.hyperisland.com/';
    icon = 'https://www.thesocialhub.co/favicon.ico';
    favicon =
      'https://www.thesocialhub.co/assets/img/favicon/apple-icon-144x144.png';
  }

  return (
    <Head>
      <title>{customTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords?.join(', ')} />
      {noFollow && <meta name="robots" content="noindex" />}
      <link rel="apple-touch-icon" href={favicon} />
      <link rel="icon" href={icon} />

      {isProduction && (
        <>
          {/* Iubenda */}
          {/* Matteo Marchesano mi ha detto di toglierlo! */}
          {/* <script
            type="text/javascript"
            src="//cdn.iubenda.com/cs/iubenda_cs.js"
            async
          /> */}
          {/* <script
            dangerouslySetInnerHTML={{
              __html: `
          var _iub = _iub || [];
          _iub.csConfiguration = 
            {"invalidateConsentWithoutLog":true,
            "cookiePolicyInOtherWindow":true,
            "askConsentAtCookiePolicyUpdate":true,
            "preferenceCookie":{"expireAfter":180},
            "consentOnContinuedBrowsing":false,
            "perPurposeConsent":true,"lang":"en",
            "siteId":${process.env.IUBENDA_SITE_ID},
            "floatingPreferencesButtonDisplay":"bottom-left",
            "cookiePolicyId":${process.env.IUBENDA_COOKIE_POLICY_ID},
            "cookiePolicyUrl":"https://talentgarden.org/en/cookie-policy/", 
            "banner":{ "acceptButtonDisplay": true,
                        "customizeButtonDisplay":true,
                        "acceptButtonColor":"#ee7660",
                        "acceptButtonCaptionColor":"white",
                        "customizeButtonColor":"#ee7660",
                        "customizeButtonCaptionColor":"#ffffff",
                        "rejectButtonDisplay":true,
                        "rejectButtonColor":"#ee7660",
                        "rejectButtonCaptionColor":"white",
                        "listPurposes":true,
                        "position":"float-center",
                        "backgroundOverlay":true,
                        "textColor":"black",
                        "backgroundColor":"white",
                        "explicitWithdrawal":true }};`,
            }}
          /> */}
          {/* Global site tag (gtag.js) - Google Analytics  */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
            }}
          />
          <>
            <meta property="og:site_name" content={siteName} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={customTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />

            <meta
              property="og:image"
              content="https://learn.talentgarden.com/images/social_image.png"
            />

            <meta
              property="og:image:secure_url"
              content="https://learn.talentgarden.com/images/social_image.png"
            />
            <meta property="og:image:width" content="1500" />
            <meta property="og:image:height" content="1000" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/talentgardenitaly/?brand_redir=237479089626498"
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@TalentGardenen" />
            <meta name="twitter:domain" content={url} />
            <meta name="twitter:title" content={customTitle} />
            <meta name="twitter:description" content={description} />
            <meta
              name="twitter:image"
              content="https://learn.talentgarden.com/images/social_image.png"
            />
          </>
        </>
      )}
    </Head>
  );
};
